import React, { useEffect, useState } from 'react'
import { Box } from 'rebass/styled-components'
import Layout from '../../templates/Home'
import Heading from '../../modules/Heading'
import Feed from '../../modules/Feed'
import { connect } from 'react-redux';


const WelcomePage = ( { user }) => {
    const [client, setClient] = useState()
    useEffect(() => {
        if (user && user.name) {
            const { salutation } = user.name
            const userName = user.name.last
            const clientRef = salutation + ' ' + userName
            setClient(clientRef)
        }
    }, [user])


    return (
        <Layout>
            <Heading size='h1' text={'Welcome ' + client} />
            <Box bg='charcoal' mt={[0, 0, 0, '11']}>
                <Box pb={['0px', '0px', '0px', '0px', 'lg']}>
                    <Feed type='underRestoration' />
                </Box>
                <Box>
                    <Feed type='prevRestored'/>
                </Box>
            </Box>
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        user: state.Auth.user,
    };
}
export default connect(mapStateToProps)(WelcomePage)
