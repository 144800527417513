import React from 'react'
import { Box } from 'rebass/styled-components'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import verifyAuth from '../system/firebase/requireAuth'
import Meta from '../components/general/Meta'

const HomeLayout = ({ children }) => {
    return (
        <>
            <Meta />
            <Box>
                <Header type='home' />
                <Box sx={{ minHeight: 'calc(100vh - 275px)' }}>{children}</Box>
                <Footer />
            </Box>
        </>
    )
}

export default verifyAuth(HomeLayout)
